@import '../../styles/propertySets.css';

.title {
  @apply --marketplaceH2FontStyles;
  margin-bottom: 10px;
}

.manageListingsLink {
  color: inherit;
  text-decoration: underline;
  &:hover {
    color: var(--marketplaceColor);
  }
}

.listingCards {
  @apply --listingCards;
}

.listingCard {
  @apply --listingCard;
}
