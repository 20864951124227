@import '../../styles/propertySets.css';

.title {
  @apply --marketplaceH1FontStyles;
  color: #fff;
  margin: 0 0 0.75rem;
}

.subTitle {
  @apply --marketplaceH3FontStyles;
  color: var(--marketplaceColor);
  font-family: var(--fontFamilyTitle);
  font-weight: var(--fontWeightSemiBold);
  margin: 0 0 0.75rem;
}

.text {
  color: #fff;
  font-size: 1.125rem;
  max-width: 27rem;
  margin: 0 0 1.5rem;
  @media (--viewportMedium) {
    font-size: 1.25rem;
    max-width: 33rem;
  }
}

.contactExpertButton {
  @apply --marketplaceButtonStylesPrimary;
  width: 220px;
  font-size: 1rem;
  @media (--viewportSmall) {
    width: 240px;
    font-size: 1.125rem;
  }
}

.contactExpertButton svg {
  @apply --marketplaceButtonStylesIcon;
}

/* .categorySection {
  @apply --flexGridItem;
  -ms-flex-preferred-size: 100%;
  flex-basis: 100%;
  max-width: 100%;
  @media (--viewportSmall) {
    -ms-flex-preferred-size: 50%;
    flex-basis: 50%;
    max-width: 50%;
  }
  @media (--viewportMedium) {
    -ms-flex-preferred-size: 25%;
    flex-basis: 25%;
    max-width: 25%;
  }
} */
