@import '../../styles/propertySets.css';

.row {
  @apply --flexGrid;
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  margin: 0;
}

.sellingRow {
}

.buyingRow {
  @media (--viewportMedium) {
    flex-direction: row-reverse;
  }
}

.column {
  @apply --flexGridItem;
  -ms-flex-preferred-size: 100%;
  flex-basis: 100%;
  max-width: 100%;

  padding: 40px 20px;
  @media (--viewportSmall) {
    padding: 40px;
  }
  @media (--viewportMedium) {
    padding: 80px;
    -ms-flex-preferred-size: 50%;
    flex-basis: 50%;
    max-width: 50%;
  }
}

.columnInner {
  @media (--viewportMedium) {
    display: inline-block;
    max-width: 500px;
    width: 100%;
    text-align: left;
  }
}

.buyingColumn {
  background-color: var(--matterColorNegative);
}

.sellingColumn {
  @media (--viewportMedium) {
    text-align: right;
  }
}

.sellColumnBackground,
.buyColumnBackground {
  background-color: var(--matterColor);
  padding: 0;
  @media (--viewportMedium) {
    @apply --backgroundImage;
    background-image: url('../../assets/ost-why-sell-bg_optim.jpg');
    background-position: top center;
    background-repeat: no-repeat;
    background-size: cover;
    padding: 5rem;
  }
  @media (--viewportLarge) {
    padding-top: 8rem;
    padding-bottom: 8rem;
  }
}

.buyColumnBackground {
  @media (--viewportMedium) {
    background-image: url('../../assets/ost-why-buy-bg_optim.jpg');
    background-position: center;
  }
}

.supplementalImage {
  display: block;
  margin: auto;
  @media (--viewportMedium) {
    display: none;
  }
}

.title {
  @apply --marketplaceH1FontStyles;
  margin-top: 0;
}

.text {
  font-size: 18px;
  max-width: 500px;
  &:last-of-type {
    margin-bottom: 1.5rem;
  }
}

.sellingList {
  padding: 0 0 0 24px;
  margin: 0 0 2rem;
}

.sellingListItem {
  font-size: 1.125rem;
  font-weight: 800;
  margin-bottom: 0.625rem;
  @media (--viewportMedium) {
    font-size: 1.25rem;
    margin-bottom: 1rem;
  }
}

.button {
  @apply --marketplaceButtonStyles;
  display: inline-block;
  padding-left: 20px;
  padding-right: 20px;
  width: auto;
}
