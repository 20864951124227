@import '../../styles/propertySets.css';

.root {
  /* Dimensions */
  width: 100%;
  height: auto;

  /* Layout */
  display: flex;
  flex: 1;
  flex-direction: column;

  padding-top: 1px;

  @media (--viewportMedium) {
    padding-top: 2px;
  }
}

.error {
  color: var(--failColor);
}

.formMargins {
  margin-bottom: 24px;

  @media (--viewportMedium) {
    margin-bottom: 32px;
  }
}

.title {
  composes: formMargins;
}

.description {
  composes: formMargins;
  flex-shrink: 0;
}

.category {
  composes: formMargins;
}

.submitButton {
  margin-top: auto;
  margin-bottom: 24px;
  flex-shrink: 0;

  @media (--viewportLarge) {
    display: inline-block;
    width: 241px;
    margin-top: 100px;
  }
}

.vehiclesContainer {
  @apply --flexGrid;
  margin-bottom: 24px;

  @media (--viewportMedium) {
    margin-bottom: 32px;
  }
}

.vehiclesContainer label {
  @apply --flexGridItem;
  -ms-flex-preferred-size: 100%;
  flex-basis: 100%;
  max-width: 100%;
  margin-bottom: 0.25rem;
}

.vehicleButton {
  @apply --flexGridItem;
  background-color: var(--matterColorNegative);
  color: var(--matterColor);
  font-family: industry, 'Roboto', Helvetica, Arial, sans-serif;
  font-size: 14px;
  margin: 0 0.25rem 0.5rem;
  min-height: 2.5rem;
  width: auto;

  &:hover,
  &:focus {
    background-color: var(--matterColorAnti);
    color: var(--matterColor);
  }
}

.vehicleButton > label,
.vehicleButton > label span,
.vehicleButton > :checked + label span {
  color: inherit !important;
  font-family: inherit !important;
  font-size: inherit !important;
  line-height: 2.5rem;
  margin: 0 auto;
  padding: 0;
  text-align: center;
}
.vehicleButton > label > span:first-child {
  display: none;
}

.vehicleButtonSelected {
  background-color: var(--marketplaceColor);
  color: var(--matterColorLight);
}
