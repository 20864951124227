@import '../../styles/propertySets.css';

.title {
  @apply --marketplaceH2FontStyles;
  margin-bottom: 10px;
  @media (--viewportLarge) {
    font-size: 40px;
  }
}

.listingCards {
  @apply --listingCards;
}

.listingCard {
  @apply --listingCard;
}
