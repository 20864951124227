@import '../../styles/propertySets.css';

.root {
}

.pageRoot {
  display: flex;
  padding-bottom: 90px;
  @media (--viewportLarge) {
    padding-bottom: 0;
  }
}

.relativeContainer {
  position: relative;
}

.loadingText {
  margin: 24px;
}

.errorText {
  color: var(--failColor);
  margin: 24px;
}

.sectionImages {
}

.thumbnailList {
  display: block;
  list-style: none;
  margin: 12px 0;
  @media (--viewportSmall) {
    @apply --flexGridItem;
    margin: 0 12px 0 0;
    padding: 0;
  }
}

.thumbnailListItem {
  cursor: pointer;
  display: inline-block;
  vertical-align: top;
  margin: 0 6px 6px;
  @media (--viewportSmall) {
    display: block;
    margin: 0 0 6px;
  }
  @media (--viewportMedium) {
    margin-bottom: 12px;
  }
}

.thumbnailImage {
  display: block;
  width: 60px;
  opacity: 0.65;

  &:hover {
    opacity: 1;
  }

  @media (--viewportSmall) {
    width: 78px;
  }
}

.thumbnailImageActive {
  opacity: 1;
}

.threeToTwoWrapper {
  /* Layout */
  display: block;
  width: 100%;
  position: relative;
  padding: 36px 0 0;
  @media (--viewportSmall) {
    @apply --flexGrid;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: reverse;
    -ms-flex-direction: row-reverse;
    flex-direction: row-reverse;
    margin: 0;
  }
}

/* Firefox doesn't support image aspect ratio inside flexbox */
.aspectWrapper {
  position: relative; /* allow positioning own listing action bar */
  padding-bottom: 66.6667%; /* 3:2 Aspect Ratio */
  background-color: var(--matterColorNegative); /* Loading BG color */

  /* Image carousel can be opened from the image, therefore we should show a pointer */
  cursor: zoom-in;

  @media (--viewportSmall) {
    @apply --flexGridItem;
    width: calc(100% - 90px);
    margin: 0 0 12px;
    padding-left: 0;
    padding-right: 0;
  }
  @media (--viewportMedium) {
    padding-bottom: 0; /* No fixed aspect on desktop layouts */
  }
}

.actionBar {
  display: flex;
  justify-content: space-between;
  /* position: absolute; */
  /* top: 13px;
    left: 13px;
    width: calc(100% - 26px); */
  color: var(--matterColorNegative);
  background-color: var(--marketplaceColor);
  z-index: 1; /* bring on top of mobile image */

  /* Action bar prevents the image click events going to the parent and
   should not show a pointer */
  cursor: initial;

  border-radius: var(--borderRadius);
}

.ownListingText {
  @apply --marketplaceH4FontStyles;
  color: var(--matterColorNegative);
  font-weight: var(--fontWeightSemiBold);
  margin: 14px 12px 11px 24px;

  @media (--viewportMedium) {
    margin: 25px 12px 22px 24px;
  }
}

.ownListingTextPendingApproval {
  color: var(--attentionColor);
}

.closedListingText {
  @apply --marketplaceH4FontStyles;
  margin: 14px 12px 11px 24px;
  text-align: center;
  width: 100%;

  @media (--viewportMedium) {
    margin: 25px 12px 22px 24px;
  }
}

.editListingLink {
  @apply --marketplaceH4FontStyles;
  font-weight: var(--fontWeightSemiBold);
  flex-shrink: 0;
  margin: 0;
  padding: 14px 24px 11px 12px;
  color: var(--matterColorNegative);

  transition: var(--transitionStyleButton);

  &:hover {
    color: var(--matterColorLight);
    text-decoration: none;
  }

  @media (--viewportMedium) {
    margin: 0;
    padding: 25px 24px 22px 12px;
  }
}

.editIcon {
  margin: -6px 7px 0 0;
}

.rootForImage {
  /* Layout - image will take space defined by aspect ratio wrapper */
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;

  transition: var(--transitionStyleButton);

  @media (--viewportMedium) {
    position: static;
    top: auto;
    bottom: auto;
    left: auto;
    right: auto;
    height: calc(0.41 * 100vw);
    max-height: 50vh;
    object-fit: cover;
    border-radius: var(--borderRadius);

    &:hover {
      transform: scale(1.005);
      box-shadow: var(--boxShadowListingCard);
    }
  }
}

.viewPhotos {
  @apply --marketplaceTinyFontStyles;
  font-weight: var(--fontWeightMedium);

  /* Position and dimensions */
  position: absolute;
  bottom: 19px;
  right: 24px;
  margin: 0;
  padding: 8px 13px 6px 13px;

  /* Colors */
  background-color: var(--matterColorLight);

  /* Borders */
  border: none;
  border-radius: var(--borderRadius);

  cursor: pointer;
  transition: var(--transitionStyleButton);

  &:hover {
    background-color: var(--marketplaceColor);
    color: var(--matterColorLight);
  }

  @media (--viewportMedium) {
    margin: 0;
  }
}

.carouselModalScrollLayer {
  width: 100vw;
  height: 100vh;
  background-color: rgba(1, 1, 1, 0.92);
}

.carouselModalContainer {
  width: 100%;
  height: 100%;
}

.contentContainer {
  @apply --flexGrid;
  @apply --contentContainer;
}

.desktopColumn {
  @apply --flexGridItem;
  -ms-flex-preferred-size: 100%;
  flex-basis: 100%;
  max-width: 100%;
  margin-bottom: 24px;
  @media (--viewportMedium) {
    margin-bottom: 48px;
  }
}

.desktopColumnLeft {
  @media (--viewportMedium) {
    -ms-flex-preferred-size: calc(100% - 400px);
    flex-basis: calc(100% - 400px);
    max-width: calc(100% - 400px);
    padding-right: 24px;
  }
  @media (--viewportLarge) {
    -ms-flex-preferred-size: calc(100% - 440px);
    flex-basis: calc(100% - 440px);
    max-width: calc(100% - 440px);
    padding-right: 48px;
  }
}

.desktopColumnRight {
  @media (--viewportMedium) {
    -ms-flex-preferred-size: 400px;
    flex-basis: 400px;
    max-width: 400px;
  }
  @media (--viewportLarge) {
    -ms-flex-preferred-size: 440px;
    flex-basis: 440px;
    max-width: 440px;
  }
}

.mainContent {
  flex-basis: 100%;
  margin-bottom: 23px;

  @media (--viewportMedium) {
    margin-top: 50px;
    margin-bottom: 51px;
    flex-shrink: 0;
  }

  @media (--viewportLarge) {
    margin-top: 50px;
    margin-bottom: 0px;
    /* contentContainer's full width - (bookingColumn + two paddings + margin + border) */
    max-width: calc(100% - 433px);
    flex-basis: calc(100% - 433px);
    flex-shrink: 0;
    flex-grow: 1;
  }
}

.bookingPanel {
  margin: 2rem 0;
}

.sectionAvatar {
  /* Rendering context to the same lavel as listing image */
  position: relative;
  /* Flex would give too much width by default. */
  width: 60px;
}

.avatarMobile {
  display: flex;

  @media (--viewportMedium) {
    display: none; /* Hide the medium avatar from the bigger screens */
  }
}

.avatarDesktop {
  display: none; /* Hide the large avatar from the smaller screens */

  @media (--viewportMedium) {
    display: flex;
  }
}

.sectionHeading {
  margin-top: 36px;
  margin-bottom: 0;
}

.newBadge {
  @apply --marketplaceButtonStylesPrimary;
  @apply --marketplaceButtonStylesXSmall;
  display: inline-block;
  margin: 0 12px 0 0;
  min-height: 30px;
  width: 55px;
}

.fromNow {
  @apply --marketplaceH5FontStyles;
  color: var(--matterColor);
  display: inline-block;
  vertical-align: middle;
  margin: 0;
}

.brand {
  margin-top: 0.875rem;
  margin-bottom: 0;
  line-height: 1;
}

.brandLink {
}

.desktopPriceContainer {
  display: block;
}

.desktopPriceValue {
  /* Font */
  @apply --marketplaceModalTitleStyles;
  color: var(--marketplaceColor);
  font-size: 26px;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 1px;
  }
}

.desktopPerUnit {
  /* Font */
  @apply --marketplaceH5FontStyles;
  color: var(--matterColor);

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.heading {
  margin-bottom: 33px;
  padding: 0 24px;

  @media (--viewportMedium) {
    margin-bottom: 0;
    padding: 0;
  }
}

.title {
  /* Font */
  @apply --marketplaceModalTitleStyles;
  color: var(--matterColorDark);

  /* Layout */
  width: 100%;
  margin-top: 0;
  margin-bottom: 0;
  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 2px;
  }
}

.author {
  width: 100%;
  @apply --marketplaceH5FontStyles;
  margin-top: 7px;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
    color: var(--matterColor);
  }
}

.authorNameLink {
  color: var(--matterColor);
}

.contactWrapper {
  display: none;

  @media (--viewportMedium) {
    display: inline;
  }
}

.separator {
  margin: 0 6px;
}

.contactLink {
  @apply --marketplaceLinkStyles;
  @apply --marketplaceH5FontStyles;
  margin: 0;
}

.sectionDescription {
  padding: 0 24px;
  margin-bottom: 35px;

  @media (--viewportMedium) {
    padding: 0;
    margin-bottom: 36px;
  }
}

.descriptionTitle {
  /* Font */
  @apply --marketplaceH2FontStyles;
  color: var(--matterColorDark);

  margin-top: 0;
  margin-bottom: 13px;
  @media (--viewportMedium) {
    font-size: 24px;
    margin-top: 0;
    margin-bottom: 12px;
  }
}

.sectionFeatures {
  padding: 0 24px;
  margin-bottom: 32px;

  @media (--viewportMedium) {
    padding: 0;
    margin-bottom: 52px;
  }
}

.featuresTitle {
  /* Font */
  @apply --marketplaceH2FontStyles;
  color: var(--matterColorDark);

  margin-top: 0;
  margin-bottom: 16px;
  @media (--viewportMedium) {
    /* font-size: 24px; */
    margin-top: 0;
    margin-bottom: 12px;
  }
}

.description {
  margin-top: 0;
  margin-bottom: 0;

  /* Preserve newlines, but collapse other whitespace */
  white-space: pre-line;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.sectionMap {
  padding: 0 24px;
  margin-bottom: 35px;

  @media (--viewportMedium) {
    padding: 0;
    margin-bottom: 0;
  }
}

.locationTitle {
  /* Font */
  @apply --marketplaceH2FontStyles;
  color: var(--matterColorDark);

  margin-top: 0;
  margin-bottom: 16px;
  @media (--viewportMedium) {
    /* font-size: 24px; */
    margin-top: 0;
    margin-bottom: 12px;
  }
}

.sectionReviews {
  padding: 0 24px;
  margin-bottom: 5px;

  @media (--viewportMedium) {
    padding: 0;
  }
  @media (--viewportLarge) {
    margin-bottom: 3px;
  }
}

.reviewsHeading {
  @apply --marketplaceH2FontStyles;
  color: var(--matterColorDark);

  margin: 52px 0 20px 0;

  @media (--viewportMedium) {
    margin: 53px 0 27px 0;
  }
  @media (--viewportLarge) {
    margin: 50px 0 26px 0;
  }
}

.sectionHost {
  @apply --flexGrid;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  position: relative;
  line-height: 1.3;
  margin-bottom: 5px;
}

.avatar,
.hostInfo,
.links {
  @apply --flexGridItem;
}

.avatar {
  -ms-flex-preferred-size: 76px;
  flex-basis: 76px;
  max-width: 76px;
}

.hostInfo {
  -ms-flex-preferred-size: calc(100% - 76px);
  flex-basis: calc(100% - 76px);
  max-width: calc(100% - 76px);
}

.shipsFromAuthor {
  @apply --marketplaceH2FontStyles;
  font-size: 24px;
  margin: 5px 0 0;
}

.shipsFromAuthor a {
  color: inherit;
}

.shipsFromHeading {
  margin-bottom: 5px;
}

.shipsFromLocation {
  font-weight: 600;
}

.authorMemberSince {
  font-size: 14px;
  margin-top: 2px;
}

.shipsFromHeading,
.links {
  -ms-flex-preferred-size: 100%;
  flex-basis: 100%;
  max-width: 100%;
}

.specs {
  margin-top: 2rem;
}

.map {
  /* background-color: #eee; */
  border: 0;
  cursor: pointer;
  /* Dimensions: Map takes all available space from viewport (excludes action button and section title) */
  height: calc(100vh - 193px);

  /* Static map: dimensions are 640px */
  /* max-width: 640px; */
  max-height: 640px;
  margin-bottom: 28px;
  padding: 0;
  width: 100%;

  @media (--viewportMedium) {
    height: 75vh;
  }

  @media (--viewportLarge) {
    height: 417px;
  }
}

.map > div {
  margin: auto;
}
.map > div:after {
  content: 'Click for interactive map';
  display: block;
}

.longWord {
  /* fallback option */
  word-break: break-all;
  /* use break-word if available */
  word-break: break-word;
  hyphens: auto;
}

.mobileAccordionOpen {
  display: block;
}

.mobileAccordionClosed {
  display: none;
  @media (--viewportMedium) {
    display: block;
  }
}

.tabNav {
  @media (--viewportMedium) {
    @apply --flexGrid;
    border-bottom: 1px solid var(--matterColorNegative);
    margin: 0;
  }
}

.tabNavButton {
  @apply --marketplaceH2FontStyles;
  background-color: var(--matterColorNegative);
  border: none;
  color: var(--matterColor);
  font-size: 18px;
  letter-spacing: 1px;
  line-height: 1;
  margin: 0;
  padding: 10px 20px;
  text-transform: capitalize;
  @media (--viewportMedium) {
    @apply --flexGridItem;
    padding: 10px 20px;
  }

  &:hover {
    background-color: var(--matterColorLight);
    color: var(--matterColorDark);
  }
}

.selectedTab {
  background-color: var(--matterColorLight);
  box-shadow: inset 0 -4px 0 0 var(--marketplaceColor);
  color: var(--matterColorDark);
  margin-bottom: -1px;
}

.panelContent {
  margin-bottom: 48px;
  padding: 20px;
}

.panelTitle {
  @apply --marketplaceH2FontStyles;
  @media (--viewportMedium) {
    font-size: 24px;
    margin-bottom: 12px;
  }
}

.panelLabel {
}
