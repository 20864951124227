@import '../../styles/propertySets.css';

.root {
  display: flex;
  flex-direction: column;
}

.listingCards {
  padding: 0 1.5rem 4rem 1.5rem;
  @apply --listingCards;
  margin: 0;
}

.listingCard {
  @apply --listingCard;
  @media (--viewportSmall) {
    -ms-flex-preferred-size: 200px;
    flex-basis: 200px;
    max-width: 200px;
  }
  @media (--viewportMedium) {
    -ms-flex-preferred-size: 250px;
    flex-basis: 250px;
    max-width: 250px;
  }
  @media (--viewportLarge) {
    -ms-flex-preferred-size: 300px;
    flex-basis: 300px;
    max-width: 300px;
  }
}

/**
 * Remove margin-right from listingCards on the last column
 * This is a special case in the world of breakpoints. Nth-of-type effects every nth item.
 * Here we take margin-right away, but we don't want it affect the next column breakpoint.
 */

.pagination {
  /* margin-top: auto; pushes pagination to the end of the page. */
  margin-top: auto;
  background-color: var(--matterColorLight);
  padding: 0 24px;

  @media (--viewportLarge) {
    padding: 0 36px;
  }
}

.noResultsPanel {
  padding: 0 24px;
  margin-bottom: 21px;

  @media (--viewportLarge) {
    padding: 0 36px;
  }
}
