@import '../../styles/propertySets.css';

.root {
  margin: 0;
}

.twoColumns {
  @media (--viewportMedium) {
    column-count: 2;
  }
}

.item {
  @apply --marketplaceBodyFontStyles;
  display: flex;
  -webkit-box-flex: 0;
  -ms-flex: 0 1 auto;
  flex: 0 1 auto;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  align-items: center;
  /* -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between; */
  margin: 0 0 10px;
  padding: 0;

  @media (--viewportMedium) {
    font-size: 16px;
  }
}

.checkIcon {
}

.hidden {
  visibility: hidden;
}

.marketplaceFill {
  fill: var(--marketplaceColor);
}

.iconWrapper {
  align-self: baseline;
  margin-right: 8px;

  /* This follows line-height */
  height: 24px;
}

.labelWrapper {
  display: inline-block;
  font-weight: bold;
  margin-right: 1rem;
  min-width: 160px;
}

.selectedLabel,
.notSelectedLabel {
  @apply --marketplaceBodyFontStyles;
  line-height: 24px;
  margin: 0;
}

.selectedLabel {
  font-weight: var(--fontWeightSemiBold);
}

.notSelectedLabel {
  color: var(--matterColorNegative);
  position: relative;

  /* line-through */
  background-image: linear-gradient(
    transparent 10px,
    var(--matterColorNegative) 10px,
    var(--matterColorNegative) 11px,
    transparent 11px
  );

  @media (--viewportMedium) {
    background-image: linear-gradient(
      transparent 12px,
      var(--matterColorNegative) 12px,
      var(--matterColorNegative) 13px,
      transparent 13px
    );
  }
}
