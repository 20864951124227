@import '../../styles/propertySets.css';

.root {
}

.tabs {
  display: none;

  @media (--viewportMedium) {
    display: flex;
    justify-content: flex-end;
    height: 55px;
    align-items: flex-end;
    padding: 13px 24px 0 24px;
  }

  @media (--viewportLarge) {
    padding: 0 36px;
  }
}

.tab {
  display: flex;
  align-items: flex-end;
  height: 100%;
  margin-left: 16px;

  &:first-child {
    margin-left: 0;
  }

  @media (--viewportLarge) {
    margin-left: 24px;
  }
}

.listingPanel {
  @apply --contentContainer;
  margin: 24px auto 0 auto;

  @media (--viewportMedium) {
    margin: 48px auto 0 auto;
  }

  @media (--viewportLarge) {
    margin: 80px auto 0 auto;
    max-width: 1200px;
  }
}

.listingSubPanel {
  width: 100%;
  margin: 24px auto 0;
}

.subHeading {
  @apply --marketplaceH2FontStyles;
}

.title {
  margin: 0 0 24px;

  @media (--viewportMedium) {
    margin: 0 0 48px;
  }
}

.listingCards {
  @apply --listingCards;
}

.listingCard {
  @apply --listingCard;
  @media (--viewportMedium) {
    -ms-flex-preferred-size: 25%;
    flex-basis: 25%;
    max-width: 25%;
  }
}

.pagination {
  /* margin-top: auto; pushes pagination to the end of the page. */
  margin: auto 24px 0 24px;

  @media (--viewportLarge) {
    margin: auto 36px 0 36px;
  }
}
