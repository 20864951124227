@import '../../styles/propertySets.css';

.title {
  @apply --marketplaceH2FontStyles;
  margin-bottom: 10px;
  text-align: center;
}

.subTitle {
  @apply --marketplaceH3FontStyles;
  color: var(--marketplaceColor);
  margin-bottom: 20px;
  text-align: center;
  @media (--viewportMedium) {
    margin-bottom: 40px;
  }
}

.categoryContainer {
  @apply --flexGrid;
}

.categorySection {
  @apply --flexGridItem;
  -ms-flex-preferred-size: 100%;
  flex-basis: 100%;
  max-width: 100%;
  @media (--viewportSmall) {
    -ms-flex-preferred-size: 50%;
    flex-basis: 50%;
    max-width: 50%;
  }
  @media (--viewportMedium) {
    -ms-flex-preferred-size: 25%;
    flex-basis: 25%;
    max-width: 25%;
  }
}

.categorySectionTitle {
  font-family: industry, 'Roboto', Helvetica, Arial, sans-serif;
  font-size: 1.25rem;
  margin-bottom: 0.5rem;
  /* &:after {
        content: '';
        background-color: var(--marketplaceColor);
        display: block;
        height: 4px;
        width: 60px;
        margin: 0.25rem 0;
    } */
}

.categoryList {
  margin-bottom: 1rem;
}

.categoryListItem {
  margin-bottom: 0.75rem;
}

.categoryLink {
  color: inherit;
  font-size: 0.875rem;
  text-decoration: underline;

  &:hover,
  &:visited {
    color: var(--matterColorDark);
  }

  &:active,
  &:focus {
    color: var(--marketplaceColor);
  }
}
