@import '../../styles/propertySets.css';

.root {
}

.sectionContent {
  width: 100%;
  max-width: 1200px;
  margin: auto;
  padding: 0 20px;
}

.welcomeContainer {
  /* background-color: var(--matterColorDark);; */
  background-color: var(--matterColorAnti);
  padding-top: 1.5rem;
  padding-bottom: 1rem;
}

.welcomeTitle {
  @apply --marketplaceH3FontStyles;
  color: var(--matterColorDark);
  margin-bottom: 0;
}

.profileLink {
  color: inherit;
  text-decoration: underline;
  &:hover {
    color: var(--marketplaceColor);
  }
}
