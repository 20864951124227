@import '../../styles/propertySets.css';

.root {
  & p {
    @apply --marketplaceH4FontStyles;
  }
  & ul,
  & ol {
    list-style: initial;
    margin: 1rem 0;
    padding: 0 0 0 1.25rem;
  }
  & h2 {
    /* Adjust heading margins to work with the reduced body font size */
    margin: 29px 0 13px 0;
  }
  & table {
    table-layout: fixed;
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 2rem;
  }

  & thead {
    background-color: var(--matterColorNegative);
    color: var(--matterColorDark);
  }

  & th,
  & td {
    border: 1px solid;
    padding: 0.75rem;
  }

  & .lastUpdated {
    @apply --marketplaceBodyFontStyles;
    margin-top: 0;
    margin-bottom: 2rem;
  }
}
