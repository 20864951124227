@import '../../styles/propertySets.css';

.root {
}

.contactExpertBox {
  background-color: var(--matterColorNegative);
  padding: 1rem;
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
  @media (--viewportLarge) {
    padding: 1.5rem;
  }
}

.contactExpertHeading {
  font-family: industry, 'Roboto', Helvetica, Arial, sans-serif;
  font-weight: var(--fontWeightBold);
}

.contactExpertCopy {
  color: var(--matterColorDark);
  font-size: 0.875rem;
  font-weight: 400;
}

.contactExpertButton {
  @apply --marketplaceButtonStylesPrimary;
  padding: 0;
  @media (--viewportSmall) {
    margin: auto;
    width: 200px;
  }
}

.modalHeading {
  @apply --marketplaceH1FontStyles;
  @media (--viewportMedium) {
    font-size: 30px;
    line-height: 1.2;
    margin-top: 0;
    margin-bottom: 18px;
  }
}

.modalMessage {
  background-color: var(--matterColorNegative);
  color: var(--matterColorDark);
  text-align: center;
  padding: 2rem 1rem;
}
.modalMessage p {
  font-size: 1.25rem;
}

.modalMessage svg {
  background-color: var(--matterColorAnti);
  border-radius: 50%;
  box-shadow: var(--boxShadowLight);
  height: 6rem;
  width: 6rem;
  margin: 0 0 2rem;
}
.modalMessageSuccess svg {
  background-color: var(--successColorLight);
  color: var(--successColor);
}
.modalMessageError svg {
  background-color: var(--failColorLight);
  color: var(--failColor);
}
