@import '../../styles/propertySets.css';

.root {
}

.sections {
  margin: 0;
  padding-top: 1px;
}

.section {
  overflow: auto;
  padding-top: 2rem;
  padding-bottom: 2rem;

  @media (--viewportMedium) {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }

  @media (--viewportLarge) {
    padding-top: 4rem;
    padding-bottom: 4rem;
  }

  @media (--viewportLargeWithPaddings) {
    padding-top: 5rem;
    padding-bottom: 5rem;
  }
}

/* Every other section has a light background */
.section:nth-of-type(2n) {
  background-color: var(--matterColorLight);
}

.sectionContent {
  width: 100%;
  max-width: 1200px;
  margin: auto;
  padding: 0 20px;
}

.sectionFullWidth {
  width: 100%;
  max-width: 100%;
}

.sectionNoPadding {
  padding: 0;
}

.sectionImgBackground {
  background-color: var(--matterColorDark);
  @apply --backgroundImage;
}

.sectionWelcomeBanner {
  background-color: var(--marketplaceColor) !important;
  color: var(--matterColorBright);

  @media (--viewportLarge) {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }
}

.sectionHeroAlt,
.sectionContactExpert {
  @apply --backgroundImage;
  padding-top: 4rem;
  padding-bottom: 4rem;
  background-image: url('../../assets/ost-parts-bg-alt.jpg');
  background-position: bottom 20% right 80%;
  @media (--viewportMedium) {
    padding-top: 8rem;
    padding-bottom: 8rem;
  }
  @media (--viewportLarge) {
    padding-top: 10rem;
    padding-bottom: 10rem;
  }
}

.sectionHeroAlt {
  background-image: url('../../assets/ost-hero-bg_optim.jpg');
  background-position: center left;
  @media (--viewportMedium) {
    padding-top: 8rem;
    padding-bottom: 4rem;
  }
  @media (--viewportLarge) {
    padding-top: 12rem;
    padding-bottom: 6rem;
  }
}
