@import '../../styles/propertySets.css';

.title {
  @apply --marketplaceH2FontStyles;
  text-align: center;

  @media (--viewportMedium) {
    margin-bottom: 23px;
  }
}

.locations {
  display: flex;
  flex-direction: column;
  margin-top: 32px;

  @media (--viewportMedium) {
    flex-direction: row;
    margin-top: 57px;
  }
}

.location {
  width: 100%;
  margin-top: 25px;

  /* Remove link's hover effect */
  &:hover {
    text-decoration: none;
  }

  @media (--viewportMedium) {
    margin-top: 0;
  }
}

.location {
  @media (--viewportMedium) {
    margin-right: 40px;
    margin-bottom: 0;
  }
}

.location:last-of-type {
  @media (--viewportMedium) {
    margin-right: 0;
  }
}

/* A wrapper for a responsive image that holds it's aspect ratio */
.imageWrapper {
  position: relative;
  width: 100%;
  border-radius: 4px;
  transition: var(--transitionStyleButton);

  &:hover {
    transform: scale(1.02);
    box-shadow: var(--boxShadowSectionLocationHover);
  }
}

/* An image wrapper that defines the images aspect ratio */
.aspectWrapper {
  padding-bottom: calc(6 / 13 * 100%); /* 13:6 Aspect Ratio */

  @media (--viewportMedium) {
    padding-bottom: calc(2 / 3 * 100%); /* 3:2 Aspect Ratio */
  }
}

.locationImage {
  /* Layout - image will take space defined by aspect ratio wrapper */
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  border-radius: 4px;
  object-fit: cover;
}

.linkText {
  @apply --marketplaceH2FontStyles;
  color: var(--matterColor);
  margin-top: 15px;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 21px;
  }
}

.locationName {
  color: var(--marketplaceColor);
}

.mapWrapper {
}

.mapContainer {
}

.mapSVG {
  color: var(--matterColorNegative);
  display: block;
  /* height: 593px; */
  margin: auto;
  max-width: 960px;
  width: 100%;
}

.mapSVG path {
  cursor: pointer;
  &:active,
  &:focus,
  &:hover {
    fill: #ed1c2a;
  }
}

.mapSVG .selected {
  fill: #ed1c2a;
}
