@import '../../styles/propertySets.css';

/* Animation for the hero elements */

@keyframes animationTitle {
  0% {
    visibility: hidden;
    opacity: 0;
    top: -20px;
  }
  100% {
    visibility: visible;
    opacity: 1;
    top: 0;
  }
}

/* Shared animation rules */
.animation {
  animation-name: animationTitle;
  animation-duration: 0.5s;
  animation-timing-function: ease-out;
  -webkit-animation-fill-mode: forwards;

  visibility: hidden;
  opacity: 1;
  position: relative;
}

.root {
  @apply --backgroundImage;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;

  /* Text positioning is done with paddings */
}

.heroContent {
  @apply --flexGrid;
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  margin: 0;
}

.heroContentInner {
  color: var(--matterColorLight);
  display: inline-block;
  max-width: 25rem;
  text-align: left;

  @media (--viewportMedium) {
  }

  @media (--viewportLarge) {
  }
}

.sellingHalf {
  @apply --backgroundImage;
  background: url('../../assets/ost-hero-selling-bg_optim.jpg');
  background-position: right top;
  @apply --flexGridItem;
  -ms-flex-preferred-size: 100%;
  flex-basis: 100%;
  max-width: 100%;
  padding: 2.5rem;
  display: none;

  @media (--viewportMedium) {
    display: block;
    -ms-flex-preferred-size: 50%;
    flex-basis: 50%;
    max-width: 50%;
    min-height: 25rem;
    padding-top: 6.25rem;
    padding-right: 6.25rem;
    text-align: right;
  }
  @media (--viewportLarge) {
    min-height: 37.5rem;
    padding-top: 7.5rem;
    padding-right: 7.5rem;
  }
}

.buyingHalf {
  @apply --backgroundImage;
  background: url('../../assets/ost-hero-buying-bg_optim.jpg');
  background-position: center;
  @apply --flexGridItem;
  -ms-flex-preferred-size: 100%;
  flex-basis: 100%;
  max-width: 100%;
  padding: 2.5rem;

  @media (--viewportMedium) {
    -ms-flex-preferred-size: 50%;
    flex-basis: 50%;
    max-width: 50%;
    min-height: 25rem;
    padding-top: 6.25rem;
    padding-left: 6.25rem;
  }
  @media (--viewportLarge) {
    min-height: 37.5rem;
    padding-top: 7.5rem;
    padding-left: 7.5rem;
  }
}

.heroMainTitle {
  @apply --marketplaceHeroTitleFontStyles;
  color: var(--matterColorLight);
  line-height: 1;
  margin-top: 0;

  composes: animation;
  animation-delay: 0.5s;

  @media (--viewportMedium) {
    font-size: 3rem;
    margin-top: 0;
  }
}

.heroList {
  padding: 0 0 0 24px;
  margin: 0 0 2rem;
}

.heroListItem {
  font-size: 1.125rem;
  font-weight: 800;
  margin-bottom: 0.625rem;
  @media (--viewportMedium) {
    font-size: 1.5rem;
    margin-bottom: 1.25rem;
  }
}

.heroText {
  font-size: 1rem;
  line-height: 1.66;
  margin: 0 0 2rem;
  @media (--viewportMedium) {
    font-size: 1.125rem;
  }
}

.heroButton {
  @apply --marketplaceButtonStyles;
  composes: animation;
  animation-delay: 0.8s;
  display: inline-block;
  margin-right: 20px;
  margin-bottom: 20px;
  padding-left: 20px;
  padding-right: 20px;
  width: auto;

  &:last-child {
    margin-right: 0;
  }
}

.heroAltTitle {
  @apply --marketplaceH1FontStyles;
  color: #fff;
  margin: 0 0 0.75rem;
  max-width: 25rem;
  @media (--viewportMedium) {
    max-width: 40rem;
  }
}

.subTitle {
  @apply --marketplaceH3FontStyles;
  color: var(--marketplaceColor);
  font-family: var(--fontFamilyTitle);
  font-weight: var(--fontWeightSemiBold);
  margin: 0 0 0.75rem;
}

.heroAltText {
  color: #fff;
  font-size: 1.125rem;
  max-width: 27rem;
  margin: 0 0 1.5rem;
  @media (--viewportMedium) {
    font-size: 1.25rem;
    max-width: 33rem;
  }
}
