@import '../../styles/propertySets.css';

.root {
}

.bannerContent {
  max-width: 1000px;
  margin-left: auto;
  margin-right: auto;

  text-align: center;
}

.bannerContent p {
  line-height: 1.66;
  &:last-child {
    margin-bottom: 0;
  }
  @media (--viewportMedium) {
    font-size: 1.125rem;
  }
}
