@import '../../styles/propertySets.css';

.root {
  /* Layout */
  display: flex;
  flex-direction: column;

  /* Remove link's hover effect */
  &:hover {
    text-decoration: none;
  }
}

.threeToTwoWrapper {
  /* Layout */
  display: block;
  width: 100%;
  position: relative;
  transition: var(--transitionStyleButton);

  &:hover {
    transform: scale(1.02);
    box-shadow: var(--boxShadowListingCard);
  }
}

/* Firefox doesn't support image aspect ratio inside flexbox */
.aspectWrapper {
  padding-bottom: 66.6667%; /* 3:2 Aspect Ratio */
  background: var(--matterColorNegative); /* Loading BG color */
}

.rootForImage {
  /* Layout - image will take space defined by aspect ratio wrapper */
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  border-radius: var(--borderRadius);
}

.info {
  /* Layout */
  /* display: flex;
  flex-direction: row; */
  padding: 16px 0 2px 0;
}

.price {
  /* Layout */
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  flex-shrink: 0;
  margin-right: 18px;
}

.priceValue {
  /* Font */
  @apply --marketplaceH3FontStyles;
  font-size: 18px;
  font-weight: 600;

  /* Remove default margins from font */
  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.title {
  /* Font */
  @apply --marketplaceH3FontStyles;
  color: var(--matterColor);
  display: -webkit-box;
  /* font-family: var(--fontFamilyTitle); */
  font-size: 16px;
  font-weight: 400;
  font-weight: var(--fontWeightSemiBold);
  height: 40px;
  line-height: 1.3;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  -moz-box-orient: vertical;
  -moz-line-clamp: 2;
  overflow: hidden;
  width: 100%;

  /* Remove default margins from font */
  margin-top: 0;
  margin-bottom: 8px;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 8px;
  }
}

.longWord {
  /* fallback option */
  word-break: break-all;
  /* use break-word if available */
  word-break: break-word;
  hyphens: auto;
}

.newBadge {
  background-color: var(--marketplaceColor);
  color: var(--matterColorBright);
  display: block;
  font-size: 14px;
  font-weight: var(--fontWeightSemiBold);
  line-height: 28px;
  text-align: center;
  width: 60px;

  position: absolute;
  top: 16px;
  left: -8px;
  z-index: 1;

  &:before {
    border-bottom: 8px solid var(--marketplaceColorDark);
    border-left: 8px solid rgba(0, 0, 0, 0);
    content: '';
    display: block;
    position: absolute;
    top: -8px;
    left: 0;
    z-index: 1;
  }
}

.shippingContainer {
  line-height: 1;
}

.shipping {
  color: var(--matterColor);
  display: inline-block;
  vertical-align: top;
  font-size: 14px;
  line-height: 1;
  margin: 0.25rem 0.5rem 0 0;

  &:last-child {
    margin-right: 0;
  }
}

.freeShipping {
  color: var(--marketplaceColor);
}
