@import '../../styles/propertySets.css';

.root {
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  height: auto;
}

.content {
  flex-grow: 1;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.footer {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100vw;
  padding: 24px;
  background-color: var(--matterColorLight);
  box-shadow: var(--boxShadowTop);
}

.avatar {
  /* Layout */
  flex-shrink: 0;
  margin: 1rem 0 0;
}

.greeting {
  /* Font */
  @apply --marketplaceH1FontStyles;
  margin-bottom: 1px;
  margin-top: 16px;
}

.logoutButton {
  @apply --marketplaceLinkStyles;
  /* Logout font is smaller and gray since the action is not recommended. */
  @apply --marketplaceH3FontStyles;
  color: var(--matterColorAnti);

  /* Position component */
  width: initial;
  margin: 4px 0 6px 0;
  padding: 0;
}

.inbox {
  /* Font */
  @apply --marketplaceH2FontStyles;
  color: var(--marketplaceColor);
  position: relative;

  margin-top: 0;
  margin-bottom: 13px;
}

.navigationLink {
  /* Font */
  @apply --marketplaceH2FontStyles;
  color: var(--marketplaceColor);

  /* Layout */
  margin-top: 0;
  margin-bottom: 11px;
}
.currentPageLink {
  color: var(--matterColorDark);
}

.notificationBadge {
  position: absolute;
  top: 4px;
  right: -26px;

  /* This is inside a link - remove underline */
  &:hover {
    text-decoration: none;
  }
}

.createNewListingLink {
  @apply --marketplaceButtonStyles;
}

.authenticationGreeting {
  /* Font */
  @apply --marketplaceH1FontStyles;

  margin-bottom: 24px;
  margin-top: var(--TopbarMobileMenu_topMargin);
}
.authenticationLinks {
  white-space: nowrap;
}

.currentPage {
  color: var(--matterColorDark);

  /* black left border */
  border-left: 5px solid black;
  margin-left: -24px;
  padding-left: 19px;
}

.mobileNavMenu {
  display: block;
  margin-top: 2rem;
  width: 100%;
}

.mobileNavItem {
  border-bottom: 1px solid var(--matterColorAnti);
  color: var(--matterColorDark);
  font-family: var(--fontFamilyTitle);
  font-weight: var(--fontWeightSemiBold);
}

.icon {
  display: inline-block;
  height: 1.5rem;
  width: 1.5rem;
}

.backIcon {
  transform: rotate(90deg);
}

.mobileNavLinkBack {
}

.hasSubMenu {
}

.mobileNavLink {
  color: var(--matterColorDark);
  display: flex;
  flex: 0 0 auto;
  padding: 0.75rem;
  justify-content: space-between;
}

.signupLink,
.loginLink {
  color: var(--marketplaceColor);
  display: inline-block;
  text-decoration: none;
  white-space: nowrap;
}

.mobileNavSubMenu {
  display: none;
  padding: 0.75rem;
}

.mobileNavSubMenuOpen .mobileNavSubMenu {
  display: block;
}
.mobileNavSubMenuOpen > .mobileNavLink {
  background-color: var(--matterColorBright);
}

.mobileNavSubMenuOpen .icon {
  transform: rotate(180deg);
}

.mobileNavChildMenuSectionHeading {
  margin: 1rem 0 0.25rem;
  &:first-child {
    margin-top: 0;
  }
}

.mobileNavChildMenu {
}

.mobileNavChildMenuItem {
  margin-bottom: 0.25rem;
}

.mobileNavChildMenuLink {
  display: inline-block;
  line-height: 1.2;
  padding: 0.25rem 0;
}
